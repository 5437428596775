export const USER_ID_KEY = 'userId'
export const USER_ID_ACCESS_TOKEN = 'userToken'
export const USER_ACCOUNTS_KEY = 'user_accounts'
export const IS_LOGIN_FAILED_STATE_KEY = 'isLoginFailedState'

export const MAIN_LEADER_BOARD_FILTER_KEY = 'main_leaderboard_filter'
export const COPY_LEADER_BOARD_FILTER_KEY = 'copy_leaderboard_filter'
export const RANK_LEADER_BOARD_FILTER_KEY = 'rank_leaderboard_filter'

export const USER_PORTFOLIO_CHART_PERIOD_KEY = 'user_portfolio_chart_period'
export const KEY_PORTFOLIO_CHART_PERIOD_KEY = 'key_portfolio_chart_period'
