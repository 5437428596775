import {createContext} from 'react'

export interface ITipId {
    type?: string
    id?: string
}
export interface ITipContext {
    tipList?: string[]
    nextOpenTip?: ITipId

    registerTip(type?: string, id?: string): () => void
    popTip(type?: string): () => void
}
export const TipContext = createContext({
    tipList: [],
    nextOpenTip: undefined,

    registerTip: (type?: string, id?: string) => {},
    popTip: (type?: string) => {},
})
