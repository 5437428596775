export const KAKAO_KEY: string = '32527a4cc4cfe0666cfb26a112d669e7'
export const DAY_IN_SECOND = 86400
export const THREE_MONTH_IN_SECOND = 777600
export const NICKNAME_MAX_LENGTH = 25
export const NICKNAME_MIN_LENGTH = 4
export const NICKNAME_LOCAL_MAX_LENGTH = 25
export const NICKNAME_LOCAL_MIN_LENGTH = 2
export const STATUS_MESSAGE_MAX_LENGTH = 200
export const API_NAME_MAX_LENGTH = 25
export const COPYLEADER_NAME_MAX_LENGTH = 25
export const COPYLEADER_STATUS_MESSAGE_MAX_LENGTH = 600
export const LEADER_MAX_LENGTH = 25
export const LEADER_MIN_LENGTH = 4
export const LEAGUE_OF_TRADERS_OFFICIAL_ID = '5d5dff97a80a5f6145c97bfd'

export const FILTER_LOGIN_BROWSER = ['KAKAOTALK', 'Line', 'FBAN', 'NAVER']
