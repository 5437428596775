import React, {useEffect, useState} from 'react'
import WizSnackbar from '@component/snackbar/WizSnackbar'
import {useSnackbarStore} from '@store/SnackbarStore'
import {Transition} from '@headlessui/react'

interface IProps {}

const SnackbarLoader: React.FC<IProps> = ({}) => {
    const item = useSnackbarStore(state => state.item)
    const [isVisible, setIsVisible] = useState(false)

    useEffect(() => {
        if (!item || (item.text?.length ?? 0) == 0) {
            return
        }

        setIsVisible(true)

        const timeout = setTimeout(() => {
            setIsVisible(false)
        }, 3000)

        return () => {
            clearTimeout(timeout)
            setIsVisible(false)
        }
    }, [item])

    return (
        <Transition
            show={isVisible}
            enter="ease-out duration-75"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className={`fixed bottom-[50px] left-0 right-0 mx-[10px] overflow-auto z-snackbar flex`}>
                <WizSnackbar
                    className={`relative flex justify-center min-w-[250px] mx-auto`}
                    text={item?.text}
                    type={item?.type}
                />
            </div>
        </Transition>
    )
}

export default SnackbarLoader
