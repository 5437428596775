import {IMe} from '@type/profile'
import {JsMsg} from '@nats-io/jetstream/lib/mod'

export type NatsChatChannel = 'ko' | 'en'
export type NatsTrendsType = 'coin' | 'user'

export const NatsSubjects = {
    OrderSub: 'public.order.>',
    NotificationsSub: (user_id: string) => `user.${user_id}.notifications.>`,
    ChatSub: (channel: NatsChatChannel) => `public.chat.${channel}`,
    ChatPub: (channel: NatsChatChannel, userId: string) => `private.chat.${channel}.${userId}`,
    TrendsSub: 'public.trends.rising.>',
}

export const BucketNames = {
    UserInfo: 'user_info',
    LiveChart: 'live_chart',
} as const

export const WatchKeys = {
    Indicator: 'indicator',
    HighVolumeOrder: 'high_volume_order',
} as const

export type TBucketUserInfo = Pick<IMe, 'name' | 'thumbnail' | 'pro_until'>

export type TConsume = {
    id: string
    subject: string
    callback: (msg: JsMsg) => void
    options?: TConsumeOptions
}

type TConsumeHandlers = {
    callback: (msg: JsMsg) => void
    onError: () => void
}

export type TConsumeOptions = {
    start_seq?: number
    batch_size?: number
}
