import {create} from 'zustand'

type ConnectionState = {
    isConnected: boolean
    setIsConnected: (isConnected: boolean) => void
}

export const useNatsConnectionStore = create<ConnectionState>(set => ({
    isConnected: false,
    setIsConnected: isConnected => set({isConnected}),
}))
