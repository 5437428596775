import React from 'react'

interface IProps {
    className?: string
    barClassName?: string
    width: number
}

const WizProgressBar: React.FC<IProps> = ({className, barClassName, width = 0}) => {
    return (
        <div className={`rounded-full ${className}`}>
            <div
                style={{width: `${width}%`}}
                className={`${barClassName} rounded-full relative max-w-[100%] transition-all duration-500 ease-in-out`}></div>
        </div>
    )
}
export default WizProgressBar
