import {useEffect} from 'react'
import {Msg} from '@nats-io/nats-core'

import {natsClient} from '@component/nats/nats'
import {NatsSubjects} from '@component/nats/type'

import {useNatsConnectionStore} from '../connection'

import {useLoginStore} from '@store/LoginStore'
import {showToast} from '@component/snackbar/WizToastMessage'
import {useVisibilityChange} from '@hook/useVisibilityChange'
import {useFocusStatus} from '@hook/useFocusStatus'

export const useGlobalNats = () => {
    const id = useLoginStore(state => state.id)
    useEffect(() => {
        void natsClient.connect()
        return () => {
            void natsClient.disconnect()
        }
    }, [id])

    const visibility = useVisibilityChange()
    useEffect(() => {
        if (visibility === 'hidden') return
        void natsClient.connect()
    }, [visibility])

    // const isFocused = useFocusStatus()
    // useEffect(() => {
    //     if (!isFocused) return
    //     void natsClient.disconnect()
    // }, [isFocused])

    return null
}

export const useNats = (subject?: string, onMessage?: (msg: Msg) => void) => {
    const isConnected = useNatsConnectionStore(state => state.isConnected)
    useEffect(() => {
        if (!isConnected || !subject) return

        const id = `${subject}_${Date.now()}`
        void natsClient.subscribe(id, subject, natsMag => {
            if (onMessage) {
                onMessage(natsMag)
            }
        })
        return () => {
            void natsClient.unsubscribe(id)
        }
    }, [isConnected, subject])
}

export const useBucketWatch = (bucketName: string, key: string, onMessage?: (value: Uint8Array) => void) => {
    const isConnected = useNatsConnectionStore(state => state.isConnected)
    useEffect(() => {
        if (!isConnected || !key) return

        const id = `${bucketName}_${key}_${Date.now()}`
        void natsClient.watch(id, bucketName, key, value => {
            if (onMessage) {
                onMessage(value)
            }
        })

        return () => {
            void natsClient.stop(id)
        }
    }, [isConnected, bucketName, key])
}

export const useNotification = () => {
    const id = useLoginStore(state => state.id)
    useNats(id && NatsSubjects.NotificationsSub(id || ''), msg => {
        const data = JSON.parse(Buffer.from(msg.data).toString('utf-8'))
        showToast(data?.message)
    })
    return null
}
