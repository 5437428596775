import {equalString} from '@util/strings'

export const amplitudeConfig = {
    amplitudeKey: process.env.NEXT_PUBLIC_AMPLITUDE_KEY,
    isSupportLog: process.env.NEXT_PUBLIC_AMPLITUDE_KEY?.length > 0,
}

export const datadogConfig = {
    isEnabled: equalString(process.env.NEXT_PUBLIC_IS_ENABLE_DATADOG, 'true'),
}

export const firebaseConfig = {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_KEY,
    authDomain: 'league-of-traders.firebaseapp.com',
    databaseURL: 'https://league-of-traders.firebaseio.com',
    projectId: 'league-of-traders',
    storageBucket: 'league-of-traders.appspot.com',
    messagingSenderId: '1024779936868',
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
    measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
}
