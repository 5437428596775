// JetStream response handle
export const NatsJetstreamResCode = {
    SUCCESS: 0,
    INVALID_REQUEST: 1,
    BANNED: 2,
    SERVER_ERROR: 3,
    TOO_MANY_REQUESTS: 4,
    REPLY_TYPE_URL_DETECTED: 5,
} as const
type TNatsJetstreamResCode = (typeof NatsJetstreamResCode)[keyof typeof NatsJetstreamResCode]

// JetStream publish error handle
export class NatsJetstreamPublishError extends Error {
    constructor(public code: TNatsJetstreamResCode, public message: string) {
        super()
        this.name = 'NatsJetstreamPublishError'
    }
}
